import { graphql } from "gatsby"
import React from "react"
import { useAppSelector } from "src/store/hooks"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

const Contact = ({ location }: { location: Location }) => {
  const { shopInfo } = useAppSelector(state => state.shop)

  const shopName = shopInfo?.company || "Demo Shop"
  const shopEmail = shopInfo?.email || "vietappeu@gmail.com"

  return (
    <Layout location={location}>
      <SEO title="Contact" />

      <h3
        style={{
          textAlign: "center",
        }}
      >
        {shopName}: {shopEmail} <br />
      </h3>
    </Layout>
  )
}

export default Contact
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
